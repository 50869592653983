import "../../../App.css";
import { Container, Row, Col } from "react-bootstrap";
import LogoCenter from "../components/LogoCenter";
import React, { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation, withRouter } from "react-router-dom";
import BlankSpace from "../components/BlankSpace";
import BottomNav from "../components/BottomNav";
//import ChangeTextSize from "components/changeText/ChangeTextSize";
import DrawerRight from "../components/DrawerRight";
import { CircularProgress, Grid, Container as Cont } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, IconButton, Toolbar } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import { ChevronLeft } from "@material-ui/icons";
import { Skeleton } from "@mui/material";
import TransactionsTable from "../components/TransactionsTable";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;

export const Transactions = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [
    { customerId, customerDetails },
    setCookieSICName,
    removeCookieSICName,
  ] = useCookies(["customerList"]);
  const [servingPeriod, setServingPeriod] = useState([]);
  const [revenueCenter, setRevenueCenter] = useState([]);
  const [menuName, setMenuName] = useState([]);
  const [selectedRevenueCenter, setSelectedRevenueCenter] = useState(
    state?.revenueCenter || "none"
  );
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  // console.log(customerDetails, "customerDetails");
  const getTransactions = useCallback(async () => {
    if (!customerId) return history.push("/");
    try {
      const { data } = await axios.get(
        `https://ecommv2.servingintel.com/customer/transaction-history/${serverId}/${siteId}/${customerDetails?.CustomerNumber}`
      );
      const newData = data.filter(
        (item) =>
          `${item.FirstName} ${item.LastName}` ===
          `${customerDetails.FirstName} ${customerDetails.LastName}`
      );

      const promises = [];
      newData.forEach((item) => {
        promises.push(
          axios
            .get(
              `https://ecommv2.servingintel.com/customer/sale-items/${serverId}/${siteId}/${item?.SaleId}`
            )
            .then((res) => ({ ...item, OrderItems: res?.data }))
        );
      });

      const results = await Promise.all(promises);
      // console.log(results)
      setTransactions(results);
    } catch (error) {
      console.log(error, "error getting transactions");
    } finally {
      setLoading(false);
    }
  }, [selectedRevenueCenter, customerId]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <div className="Welcome modal-container">
      <LogoCenter
        selectedRevenueCenter={selectedRevenueCenter}
        hasUserInfo={false}
      />
      <BlankSpace />
      <DrawerRight />

      <Container fluid="md" className="welcomeCustom">
        <Box>
          <Cont
            maxWidth="md"
            sx={{
              padding: 2,
            }}
          >
            <Box>
              <IconButton onClick={handleGoBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Box marginTop={2}>
                  <Typography variant="h5" fontWeight={500}>
                    {loading ? (
                      <Skeleton
                        variant="h5"
                        width={200}
                        sx={{ marginBottom: 1 }}
                      />
                    ) : (
                      "You now have"
                    )}
                  </Typography>
                  <Typography variant="h4" fontWeight={500}>
                    {loading ? (
                      <Skeleton variant="h4" />
                    ) : (
                      <div>
                        <strong>{transactions.length}</strong> transactions{" "}
                      </div>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box marginTop={2}>
                  {loading ? (
                    <Skeleton variant="h5" sx={{ marginBottom: 1 }} />
                  ) : (
                    <Typography variant="h5" fontWeight={500}>
                      Current Balance:{" "}
                      <strong>
                        {" "}
                        $
                        {Math.abs(
                          (customerDetails.CreditLimit -
                            customerDetails.AvailableCredit) /
                            100
                        ).toFixed(2)}
                      </strong>
                    </Typography>
                  )}
                  {loading ? (
                    <Skeleton variant="h5" sx={{ marginBottom: 1 }} />
                  ) : (
                    <Typography variant="h5" fontWeight={500}>
                      Meal Credit Available:{" "}
                      <strong>{customerDetails?.MealCount || 0}</strong>
                    </Typography>
                  )}
                  {/* Change the Legend for Meal Plan Type based on ID here */}
                  {loading ? (
                    <Skeleton variant="h5" />
                  ) : (
                    <Typography variant="h5" fontWeight={500}>
                      Meal Plan Type:
                      <strong>
                        {customerDetails.MealPlanID === 1 ? (
                          <span> Gold</span>
                        ) : customerDetails.MealPlanID === 2 ? (
                          <span> Silver</span>
                        ) : (
                          <span> Default</span>
                        )}
                      </strong>
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                marginTop: 2,
                padding: 2,
                background: "white",
              }}
            >
              {loading ? (
                <div>
                  <Skeleton height={100} />
                  <Skeleton height={80} />
                  <Skeleton height={80} />
                  <Skeleton height={80} />
                  <Skeleton height={80} />
                  <Skeleton height={80} />
                </div>
              ) : (
                <TransactionsTable transactions={transactions} />
              )}
            </Box>
          </Cont>
        </Box>
      </Container>
      <BlankSpace />
      <BlankSpace />
      <BottomNav sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} />
      {/* <ChangeTextSize /> */}
      {/* <ScrollToDown /> */}
    </div>
  );
};
